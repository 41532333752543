import React from "react";
import {
  Container,
  Grid,
  Segment,
} from "semantic-ui-react";

import LayoutInner from "../components/layout/layout-inner";
import SEO from "../components/seo/seo";
import SecondaryNavBar from "../components/secondary-nav-bar/secondary-nav-bar";
import HeadingOverImage from "../components/heading-over-image/heading-over-image";

import style from "./contact.module.css";
import {
  PUPIL_PREMIUM_EMAIL,
  SCHOOLS_EMAIL,
  SCHOOLS_PHONE,
  SCHOOLS_PHONE_WITH_COUNTRY_CODE,
  SCHOOL_RESOURCES_EMAIL
} from "../constants";

const Page = () => (
  <LayoutInner microsite>
    <SEO title="How to get in touch" keywords={[`rocksteady`]} />

    <Container>
      <SecondaryNavBar
        product="generic"
        title="How to get in touch"
        titleActive={true}
        titlePath="/contacts/"
        links={[]}
      />

      <HeadingOverImage
        text="Got a question we can help you with?"
        img="/images/contacts/contacts_banner.jpg"
        siteSection="generic"
      />

      <div className={style.contactPageWrapper}>
        <Grid padded stackable>
          <Grid.Row>
            <Grid.Column className={style.greyColumn}>
              <Segment basic className={style.contactLinks}>
                <h2>HOW TO GET IN TOUCH</h2>
                <p>
                  The Rocksteady team are on hand ready to help you in any way we can. So please do reach out to a 
                  School Champion if you have a question, no matter what it is. 
                </p>
                <p>
                  <strong>For help using the free resources</strong><br />
                  <a href={`mailto:${SCHOOL_RESOURCES_EMAIL}`}>
                    {SCHOOL_RESOURCES_EMAIL}
                  </a>
                </p>

                <p>
                  <strong>To book a free music consultation</strong><br />
                  <a href={`mailto:${SCHOOLS_EMAIL}`}>
                    {SCHOOLS_EMAIL}
                  </a>
                </p>

                <p>
                  <strong>To find out more about Bursary and Pupil Premium</strong><br />
                  <a href={`mailto:${PUPIL_PREMIUM_EMAIL}`}>
                    {PUPIL_PREMIUM_EMAIL}
                  </a>
                </p>

                <p>
                  <strong>For anything else</strong><br />
                  <a href={`mailto:${SCHOOLS_EMAIL}`}>
                    {SCHOOLS_EMAIL}
                  </a>
                </p>
              </Segment>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column className={style.greyColumn}>
              <Segment basic>
                <div className={style.bigBlueText}>
                  Or call
                  {' '}
                  <a href={`tel:${SCHOOLS_PHONE_WITH_COUNTRY_CODE}`}>
                    {SCHOOLS_PHONE}
                  </a>
                </div>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </Container>
  </LayoutInner>
);

export default Page;
