import React from "react";
import { ParallaxBanner } from "react-scroll-parallax";
import colors from "../colors";
import style from "./style.module.css";

const HeadingOverImage = ({ text, img, siteSection }) => {
  let imageStyle = {
    backgroundColor: colors.generic.imageBG,
    height: "auto"
  };

  if (siteSection) {
    imageStyle.backgroundColor = colors[siteSection].imageBG;
  }

  return (
    <div className={style.panel}>
      <ParallaxBanner
        className={style.banner}
        layers={[
          {
            image: img,
            amount: 0.3
          }
        ]}
        style={imageStyle}
      >
        <h1>{text}</h1>
      </ParallaxBanner>
    </div>
  );
};

export default HeadingOverImage;
